<template>
  <div>
    <!--工具栏-->
    <div id="toolbar" ref="toolbar">
      <div id="toolsbarLeft" ref="toolsbarLeft">
        <!--标题-->
        <Icon type="logo-codepen" size="28" v-show="titleShow" />
        <div
          style="
            margin-top: 3px;
            padding: 0 5px;
            font-size: 16px;
            margin-right: 50px;
            white-space: nowrap;
          "
          v-show="titleShow"
          ref="title"
        >
          <slot name="title"></slot>
        </div>

        <slot name="toolsbarLeft">
          <!--靠左工具-->
        </slot>
      </div>
      <div id="toolsbarRight" ref="toolsbarRight">
        <slot name="toolsbarRight">
          <!--靠右工具-->
        </slot>
      </div>
      <div id="moreRight" v-if="moreTools.moreToolsShow">
        <Button
          :type="moreTools.type"
          :icon="moreTools.icon"
          @click="selectQuery = true"
          >{{ moreTools.moreToolsName }}</Button
        >
      </div>
       <Drawer
          :title="moreTools.moreToolsName"
          v-model="selectQuery"
          :placement="moreTools.placement"
        > <!--更多工具弹窗：可自定义按钮，执行this.$refs.liefengContent.showMoreTools();-->
          <slot name="moreToolsRight"></slot>
          
        </Drawer>
    </div>

    <!--编辑区-->
    <div id="contentarea" ref="contentarea">
      <slot name="contentArea"> </slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    moreTools: {
      type: Object,
      default: {
        moreToolsShow: false,//是否显示侧窗按钮
        moreToolsName: "查询",//按钮名称
        icon: "",//按钮图标
        type:"error",//按钮样式
        placement: "right"
    },
    moreStyle: {
      type: Object,
      default: {
            height: 'calc(100% - 55px)',
            overflow: 'auto',
            paddingBottom: '53px',
            position: 'static',
          }
    }
  }
  },
  data() {
    return {
      titleShow: false,
      selectQuery: false,
    };
  },
  mounted() {
    if (this.$refs.title.innerText != "") {
      this.titleShow = true;
    }
  },
  methods:{
      showMoreTools(){
          this.selectQuery=true;
      }
  }
};
</script>

<style scoped>
#toolbar {
  min-height: 50px;
  background: #fafafa;
  display: flex;
  top: 0;
  position: fixed;
  min-width: 1000px;
  width: 100%;
  box-shadow: 0 0 1px 1px #ddd;
  z-index: 2;
}
#toolsbarLeft {
  position: relative;
  margin: 10px;
  float: left;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

#toolsbarLeft > div > .bread {
  margin-left: 74px;
}

#toolsbarRight {
  position: relative;
  margin: 10px;
  float: right;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
#toolsbarRight button,
#toolsbarLeft button {
  margin: 0 5px;
}

#moreRight {
  float: right;
  margin: 10px;
}

.moreRight-btn {
  border-radius: 50px;
}

#contentarea {
  top: calc(55px);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 10px 10px 15px;
  line-height: 1.5em;
  overflow: auto;
  z-index: 1;
}
.tooldiv {
  line-height: 30px;
  text-align: right;
  white-space: nowrap;
}
</style>
