<template>
<div ref="lfTb">
        <Table ref="lftable" :highlight-row="highlightRow" @on-current-change="currentChangeFn"  :border="tableData && tableData.length" stripe :height="tableHeight" :columns="talbeColumns" :loading="loading" :data="tableData" @on-selection-change="tableSelete" @on-sort-change="tableSort" :show-summary="showsummary" :summary-method="summaryData!=null&&summaryData!={}?handleSummary:null">
        </Table>
        <Page v-if="!hidePage" style="float:right;margin:10px;" @on-change="currentPage" :current="curPage" @on-page-size-change="hadlePageSize" :page-size="pageSize" :total="total" :page-size-opts="pagesizeOpts" size="small" show-total show-elevator show-sizer />
</div>
</template>

<script>

export default {
    name: "liefengtable",
    props: {
        tableData: Array, //传入的数据集
        talbeColumns: Array,//传入的表头集
        loading:Boolean,//传入的加载等待开关
        height:String,//表格高度
        fixTable:Boolean,//满屏固定表格，自定义height将无效

        pageSize:Number,//自定义每页显示数量
        pagesizeOpts:Array,//每页条数切换的配置
        total: Number,//总数
        curPage: Number,//当前页
        hidePage:Boolean,//是否隐藏分页

        highlightRow:{type:Boolean,default:false},//选中单行

        showsummary:{type:Boolean,default:false},//显示合计行
        summaryData:{type:Object,default:() => {return {}} },//指定的合计数据
        currentChange:{type:Object,default:() => {return {}} },//选中单行触发
    },
    components: {
    },
    data() {
        return {
            tableHeight:'',
            selection: [],
            delData: {},
            pSize:0,
        };
    },
    created() {
        //初始化值
        if(this.showsummary){
            this.tableHeight=this.height-50;
        }else{
            this.tableHeight=this.height;
        }

        this.pSize=this.pageSize==undefined||this.pageSize<1?10:this.pageSize;
    },
    mounted() {
        var that=this;
        //  this.tableHeight=this.height;
        if(this.fixTable){//自动满屏
            this.$(window).resize(this.resize);
            this.resize();
        }
    },
    methods: {
        resize(){
            this.tableHeight = window.innerHeight - (this.$refs.lftable?this.$refs.lftable.$el.offsetTop:10) - 120 ;
            if(this.showsummary){
            this.tableHeight=this.tableHeight-50;
            }
        },
        hadlePageSize(val) {//改变每页条数
            this.pSize=val;
            if (this.curPage === 1) {
                this.$emit('hadlePageSize', {
                page: 1,
                pageSize: val
                })
            }

        },
        currentPage(val) {
            this.$emit('hadlePageSize', {
                page: val,
                pageSize: this.pSize
            })
        },

        tableSort(sortdata){
          this.$emit("tableSort", sortdata);
        },
        tableSelete(selection) {
            this.selection = selection;
            this.$emit("tableSelect", selection);
        },
        handleSummary({ columns, data }){
           return this.summaryData;
        },
        currentChangeFn(newRow,oldRow) {
            this.$emit("currentChange", newRow,oldRow);
        },
        exporta(){
            this.$Modal.confirm({
                title: "温馨提示",
                content:"是否当前数据</p>",
                onOk: () => { 
                this.$refs.lftable.exportCsv({
                filename: '小程序数据'
            })}})
        },

    },
};
</script>

<style lang="less" scoped>
/deep/ .ivu-page-item-jump-next:after,
    .ivu-page-item-jump-prev:after {
        content: "\2022\2022\2022" !important;
}
/deep/.ivu-table-tip{
    &::-webkit-scrollbar{
        height: 10px !important;
        background: #2d8cf0 !important;
        color: #2d8cf0 !important;
    }
    &::-webkit-scrollbar-thumb{
        background: #2d8cf0 !important;
        color: #2d8cf0 !important;
    }
}
/deep/.ivu-table-body,.ivu-table-overflowX{
    &::-webkit-scrollbar{
        height: 10px !important;
        background: #2d8cf0 !important;
        color: #2d8cf0 !important;
    }
    &::-webkit-scrollbar-thumb{
        background: #2d8cf0 !important;
        color: #2d8cf0 !important;
    }
}
</style>
